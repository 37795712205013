import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { User, Package, Heart, Mail } from 'lucide-react';
import { Navigation } from '../components/Navigation';
import { AboutSection } from '../components/sections/AboutSection';
import { ResourcesSection } from '../components/sections/ResourcesSection';
import { ValuesSection } from '../components/sections/ValuesSection';
import { ContactSection } from '../components/sections/ContactSection';
import { useActiveSection } from '../hooks/useActiveSection';

const styles = `
  @font-face {
    font-family: 'Avenir Next';
    src: local('Avenir Next');
  }

  * {
    font-family: 'Avenir Next', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
`;

const navItems = [
    { id: 'about', label: 'About Me', icon: User },
    { id: 'resources', label: 'Product Resources', icon: Package },
    { id: 'values', label: 'Values', icon: Heart },
    { id: 'contact', label: 'Where you can reach me', icon: Mail },
  ];
  
  const BackupApp = () => {
    const activeSection = useActiveSection(navItems);
    
    const scrollToSection = (id: string) => {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };
  
    return (
      <div className="min-h-screen bg-gray-50">
        <style>{styles}</style>
        <Navigation
          items={navItems}
          activeSection={activeSection}
          onNavigate={scrollToSection}
        />
        <main className="space-y-[10vh]">
          <AboutSection />
          <ResourcesSection />
          <ValuesSection />
          <ContactSection />
        </main>
      </div>
    );
  };

  export default BackupApp;