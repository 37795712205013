import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NewHomePage from './pages/NewHomePage';
import BackupPage from './pages/BackupPage';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBURK_AHg8QSrP6C7Tt66SrlFLk4G-K6iI",
  authDomain: "gritty.digital",
  projectId: "onlypp",
  storageBucket: "onlypp.appspot.com",
  messagingSenderId: "381860474862",
  appId: "1:381860474862:web:7c8ef04a1858f2fb7368ff",
  measurementId: "G-4RS29S132X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewHomePage />} />
        <Route path="/backup" element={<BackupPage />} />
      </Routes>
    </BrowserRouter>
  );
};

  
  export default App;
