import React, { useState, useEffect} from 'react';
import { Clock, ArrowRight, Users, CheckCircle, ChevronDown, Lightbulb, Compass, Rocket, LucideIcon} from 'lucide-react';

type BadgeType = 'NOW' | 'FUTURE';

type ServiceItem = {
  title: string;
  badge?: BadgeType;
  items: string[];
};

type PhaseTitle = 'Product Strategy' | 'Product Solution' | 'Product Execution';

type WorkshopItem = {
  title: string;
  description: string;
  outcomes: string[];
  participants: string[];
  badge?: BadgeType;
};

const phaseIcons: Record<PhaseTitle, LucideIcon> = {
    'Product Strategy': Lightbulb,
    'Product Solution': Compass,
    'Product Execution': Rocket
  };

type ProductPhaseData = {
  title: string;
  duration: string;
  services: ServiceItem[];
  workshops: WorkshopItem[];
};

type ServicesData = {
  strategy: ProductPhaseData;
  solution: ProductPhaseData;
  execution: ProductPhaseData;
};

type ProductServicesPageProps = {
  heroBackgroundUrl?: string;
  onCtaClick?: () => void;
  servicesData?: ServicesData;
};

const Navbar = () => {
    const [activeSection, setActiveSection] = useState('hero');
    const [isScrolled, setIsScrolled] = useState(false);
  
    const navItems = [
      { id: 'strategy', label: 'Strategy', Icon: Lightbulb },
      { id: 'solution', label: 'Solution', Icon: Compass },
      { id: 'execution', label: 'Execution', Icon: Rocket }
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
        
        const sections = ['hero', 'strategy', 'solution', 'execution'];
        const sectionElements = sections.map(id => 
          document.getElementById(id)
        );
  
        const currentSection = sectionElements.reduce((acc, section) => {
          if (!section) return acc;
          const rect = section.getBoundingClientRect();
          if (rect.top <= 100) return section.id;
          return acc;
        }, 'hero');
  
        setActiveSection(currentSection);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToSection = (sectionId: string) => {
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = sectionId === 'hero' ? 0 : 80;
        const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };
  
    return (
      <nav 
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-neutral-950/90 backdrop-blur-sm shadow-lg' : 'bg-transparent'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="flex items-center justify-between h-20">
            <button
              onClick={() => scrollToSection('hero')}
              className={`text-xl font-bold transition-colors duration-300 ${
                activeSection === 'hero' ? 'text-purple-400' : 'text-white hover:text-purple-400'
              }`}
            >
              Product
            </button>
  
            <div className="hidden md:flex items-center space-x-8">
              {navItems.map(({ id, label, Icon }) => (
                <button
                  key={id}
                  onClick={() => scrollToSection(id)}
                  className={`relative py-2 transition-colors duration-300 group flex items-center gap-2 ${
                    activeSection === id ? 'text-yellow-400' : 'text-white hover:text-yellow-400'
                  }`}
                >
                  <Icon className="w-4 h-4" />
                  {label}
                  <div 
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-yellow-400 transform origin-left transition-transform duration-300 ease-out"
                    style={{ transform: activeSection === id ? 'scaleX(1)' : 'scaleX(0)' }} 
                  />
                </button>
              ))}
            </div>
  
            <button className="md:hidden text-white hover:text-purple-400 transition-colors">
              <ChevronDown className="w-6 h-6" />
            </button>
          </div>
        </div>
      </nav>
    );
  };

const Badge = ({ type }: { type: BadgeType }) => {
    const baseStyles = "absolute top-0 right-0 px-2 md:px-3 py-1 text-xs font-medium rounded-full uppercase tracking-wider shadow-sm";
    
    if (type === "NOW") {
      return (
        <div className="relative">
          <div className={`${baseStyles} bg-emerald-100/90 text-emerald-700 border border-emerald-200/50 backdrop-blur-sm`}>
            Now
          </div>
        </div>
      );
    }
    
    return (
      <div className="relative">
        <div className={`${baseStyles} bg-orange-100/90 text-orange-700 border border-orange-200/50 backdrop-blur-sm`}>
          Future
        </div>
      </div>
    );
  };
  
  const ServiceCarousel = ({ services }: { services: ServiceItem[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    return (
      <div className="mb-8 md:mb-16">
        <div className="relative -mx-4 px-4 md:px-0">
          {/* Added padding-top to prevent cutoff and overflow-visible to allow growth */}
          <div className="flex overflow-x-auto overflow-y-visible pt-4 pb-4 scrollbar-hide snap-x snap-mandatory touch-pan-x">
            {services.map((service, index) => (
              <div
                key={index}
                className="flex-none w-[85%] md:w-1/3 px-2 md:px-4 transition-all duration-500 cursor-pointer snap-start"
                onClick={() => setCurrentIndex(index)}
              >
                <div 
                  className={`bg-neutral-900/95 backdrop-blur-sm p-6 rounded-2xl h-full transition-all relative
                    ${index === currentIndex 
                      ? 'shadow-xl border-yellow-400/50 border-2 scale-105 opacity-100 -translate-y-2 z-10' 
                      : 'shadow-md border-neutral-800 border hover:shadow-lg hover:border-yellow-400/30 opacity-80 scale-95 hover:scale-100'
                    }`}
                  style={{
                    // Ensure smooth transition for all transforming properties
                    transition: 'all 0.3s ease-in-out',
                    transformOrigin: 'center center'
                  }}
                >
                  {/* Rest of the service card content remains the same */}
                  <div className="relative">
                    {service.badge && <Badge type={service.badge} />}
                    <h3 className="text-xl lg:text-2xl font-medium text-yellow-400 mb-6 pr-20">
                      {service.title}
                    </h3>
                    <ul className="space-y-4">
                      {service.items.map((item, i) => (
                        <li 
                          key={i} 
                          className={`flex items-start transition-colors
                            ${index === currentIndex 
                              ? 'text-white' 
                              : 'text-neutral-400 group-hover:text-neutral-300'
                            }`}
                        >
                          <CheckCircle className={`w-5 h-5 mr-3 flex-shrink-0 mt-0.5
                            ${index === currentIndex ? 'text-yellow-400' : 'text-yellow-600'}`} 
                          />
                          <span className="text-base lg:text-lg">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  
        <div className="flex justify-center mt-6 md:mt-8 space-x-2">
          {services.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full transition-all duration-500 
                ${index === currentIndex 
                  ? 'bg-yellow-400 w-8' 
                  : 'bg-neutral-700 hover:bg-neutral-600'}`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    );
  };
  
  const WorkshopCard = ({ title, description, outcomes, participants, badge }: WorkshopItem) => (
    <div className="bg-neutral-900/95 backdrop-blur-sm p-6 md:p-8 rounded-xl md:rounded-2xl border border-neutral-800 hover:border-purple-400/30 transition-all group hover:shadow-xl hover:-translate-y-1 relative h-full">
      <div className="relative min-h-[320px] flex flex-col">
        <div className="mb-6">
          <div className="relative">
            {badge && <Badge type={badge} />}
            <h4 className="text-lg md:text-xl font-medium text-purple-400 mb-3 pr-20">{title}</h4>
            <p className="text-sm md:text-base text-neutral-300">{description}</p>
          </div>
        </div>
  
        <div className="mb-6">
          {participants && (
            <div className="flex items-start text-neutral-400">
              <Users className="w-4 h-4 mr-2 text-purple-400 mt-1 flex-shrink-0" />
              <div className="flex-1 min-w-0">
                <div className="font-medium text-white mb-2 text-sm md:text-base">Key Participants</div>
                <div className="flex flex-wrap gap-2">
                  {participants.map((role, index) => (
                    <span 
                      key={index} 
                      className="inline-block bg-neutral-800 text-xs md:text-sm text-purple-300 px-2 py-1 rounded-md border border-purple-400/20"
                    >
                      {role}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
  
        <div className="space-y-2 mt-auto">
          {outcomes.map((outcome, i) => (
            <div key={i} className="flex items-start text-neutral-400 group-hover:text-white transition-colors">
              <ArrowRight className="w-4 h-4 mr-2 text-purple-400 flex-shrink-0 mt-1" />
              <span className="text-sm md:text-base flex-1 min-w-0">{outcome}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  
  const ProductPhase = ({ title, duration, services, workshops }: ProductPhaseData) => {
    // Safely get the icon component
    const PhaseIcon = phaseIcons[title as PhaseTitle] || Lightbulb;
  
    return (
      <section className="relative">
        <div className="pl-6 md:pl-8 relative">
          <div className="bg-neutral-950/80 backdrop-blur-sm rounded-3xl shadow-2xl p-8 md:p-12
              before:absolute before:inset-0 before:rounded-3xl before:bg-gradient-to-b before:from-neutral-900/50 before:to-transparent before:-z-10">
            <div className="relative mb-16">
              <div className="relative flex flex-col md:flex-row md:items-center gap-4 mb-4">
                <div className="flex items-center gap-3">
                  <PhaseIcon className="w-8 h-8 text-yellow-400" />
                  <h2 className="text-2xl md:text-3xl lg:text-4xl font-medium text-yellow-400">
                    {title}
                  </h2>
                </div>
                <div className="flex items-center px-4 py-2 bg-neutral-900/90 backdrop-blur-sm text-white rounded-full border border-yellow-400/20 shadow-sm w-fit">
                  <Clock className="w-4 h-4 mr-2 text-yellow-400" />
                  <span className="text-sm md:text-base">{duration}</span>
                </div>
              </div>
            </div>
  
            <ServiceCarousel services={services} />
  
            <div className="relative">
              <div className="relative mb-8">
                <h3 className="text-xl md:text-2xl font-medium text-purple-400 mb-4">Workshops</h3>
                <div className="h-px bg-gradient-to-r from-purple-400/30 to-transparent" />
              </div>
  
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
                {workshops.map((workshop, index) => (
                  <WorkshopCard key={index} {...workshop} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  

const defaultServicesData: ServicesData = {
        strategy: {
          title: "Product Strategy",
          duration: "4-6 weeks",
          services: [
            {
              title: "Market Research",
              badge: "FUTURE",
              items: [
                "Target Market Analysis & Segmentation",
                "Competitive Landscape Mapping",
                "Industry Trends & Opportunities"
              ]
            },
            {
              title: "Business Case Creation",
              badge: "FUTURE",
              items: [
                "Value Proposition Design",
                "Revenue Model Definition",
                "Product Pricing"
              ]
            },
            {
              title: "Product Clarity",
              items: [
                "Directional Clarity",
                "Role Clarity",
                "Principles",
                "Communication Framework"
              ]
            },
            {
              title: "User Research",
              items: [
                "User Interviews & Surveys",
                "Behavior Analysis",
                "Customer Need Finding"
              ]
            }
          ],
          workshops: [
            {
              title: "Product Positioning",
              badge: "NOW",
              description: "Why to buy your product?",
              outcomes: [
                "Clear value proposition",
                "Competitive differentiators",
                "Target customer profile",
                "Jobs-to-be-done"
              ],
              participants: ["PM", "Marketing", "Tech", "UX Lead", "Sales", "CS"]
            },
            {
              title: "OKR Framework",
              badge: "NOW",
              description: "Establish metrics that align team efforts with strategic goals",
              outcomes: [
                "Key business objectives",
                "Measurable key results",
                "Success indicators"
              ],
              participants: ["PM", "UX", "Marketing", "Sales", "CS"]
            },
            {
              title: "North Star Metric",
              badge: "FUTURE",
              description: "Identify the core metric that defines your product's success",
              outcomes: [
                "Primary success metric",
                "Supporting metrics",
                "Measurement framework"
              ],
              participants: ["PM", "Sales", "Tech", "Marketing", "CS"]
            }
          ]
        },
        solution: {
          title: "Product Solution",
          duration: "6-8 weeks",
          services: [
            {
              title: "Experience Design",
              items: [
                "Journey Mapping",
                "Information Architecture",
                "Interaction Design"
              ]
            },
            {
              title: "Solution Validation",
              items: [
                "Prototype Testing",
                "Technical Assessment",
                "Feasibility Studies"
              ]
            },
            {
              title: "Technical Roadmap",
              badge: "NOW",
              items: [
                "Product Requirement Documents",
                "Milestone planification",
                "Task breakdown"
              ]
            }
          ],
          workshops: [
            {
              title: "Product Ideation",
              description: "Collaborative session to generate innovative product ideas and solutions",
              outcomes: [
                "Empathy map creation",
                "Double diamond framework application",
                "Validated concepts"
              ],
              participants: ["PM", "UX", "Tech", "SME"]
            },
            {
              title: "Journey Mapping",
              description: "Map the complete user journey to identify opportunities",
              outcomes: [
                "User journey map",
                "Pain points",
                "Opportunity areas"
              ],
              participants: ["PM", "UX", "SME"]
            },
            {
              title: "Architecture Planning",
              description: "Define technical architecture and implementation approach",
              outcomes: [
                "System architecture",
                "Technology stack",
                "Integration points"
              ],
              participants: ["Tech", "DevOps", "QA"]
            }
          ]
        },
        execution: {
          title: "Product Execution",
          duration: "Ongoing",
          services: [
            {
              title: "Technical Implementation",
              items: [
                "Code development & testing",
                "Deployment & infrastructure",
                "Quality & performance metrics"
              ]
            },
            {
              title: "Agile Coaching",
              badge: "NOW",
              items: [
                "Framework selection based on team size & culture",
                "Process implementation & adaptation",
                "Continuous improvement practices"
              ]
            }
          ],
          workshops: [
            {
              title: "Project Kick-off",
              badge: "NOW",
              description: "Align team on project goals, scope, and approach",
              outcomes: [
                "Project vision alignment",
                "Team roles & responsibilities",
                "Initial roadmap"
              ],
              participants: ["PM", "Tech", "UX", "QA", "DevOps"]
            },
            {
              title: "Estimation Workshops",
              badge: "NOW",
              description: "Collaborative effort estimation and planning",
              outcomes: [
                "Team alignment on estimates",
                "Effort predictability",
                "Risk identification & mitigation"
              ],
              participants: ["Tech", "DM", "QA", "PM"]
            },
            {
              title: "Design Sprints",
              badge: "FUTURE",
              description: "Compressed innovation cycle that squeezes discovery, design, and validation into one intense week to rapidly validate ideas",
              outcomes: [
                "Rapid prototypes",
                "Validated solutions",
                "Quick market feedback"
              ],
              participants: ["Tech", "UX", "PM", "QA"]
            }
          ]
        }
      };


const noop = () => {
  return undefined;
};

const ProductServicesPage = ({
    heroBackgroundUrl = "/api/placeholder/1920/1080",
    onCtaClick = () => {
      const strategySection = document.getElementById('strategy');
      if (strategySection) {
        const offset = 80;
        const y = strategySection.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
    servicesData = defaultServicesData
  }: ProductServicesPageProps) => {
    return (
      <div className="min-h-screen bg-neutral-950">
        <Navbar />
        <div id="hero" className="relative min-h-[600px] h-screen flex items-center justify-center overflow-hidden">
          <img
            src={heroBackgroundUrl}
            alt="Hero background"
            className="absolute w-full h-full object-cover opacity-20"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-neutral-950/80 to-neutral-950" />
          <div className="relative w-full max-w-7xl mx-auto px-4 md:px-6 text-center md:text-left">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-white max-w-4xl leading-tight mb-6 md:mb-8">
              Transform your product idea into
              <span className="text-purple-400"> reality</span>
            </h1>
            <p className="text-lg md:text-xl text-gray-400 max-w-2xl mb-8 md:mb-12">
              Ideas that follow a clear strategy have a software solution with optimal execution using learning loops.
            </p>
            <button
              className="px-6 md:px-8 py-3 md:py-4 bg-purple-500 text-white rounded-full font-medium hover:bg-purple-400 transition-colors shadow-lg hover:shadow-xl"
              onClick={onCtaClick}
            >
              START NOW
            </button>
          </div>
        </div>
  
        <div className="relative max-w-7xl mx-auto px-4 md:px-6 py-16 md:py-24 lg:py-32">
          <div className="relative space-y-16 md:space-y-24 lg:space-y-32">
            {Object.entries(servicesData).map(([key, phase], index) => (
              <div id={key} key={index}>
                <ProductPhase {...phase} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default ProductServicesPage;